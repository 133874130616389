/* Homepage slider head */
.slider_home_section {
  display: flex;

  .block_slider_c {
    padding-left: 0;
    margin-bottom: 0;
    list-style-type: none;
    width: 100%;
    max-width: 36rem;

    .block_slider {
      height: calc(100% / 4);
      background-color: var(--color1);

      a {
        display: flex;
        align-items: center;
        height: 100%;
        font-size: 2rem;
        font-weight: 600;
        line-height: 1.2;
        color: #fff;
        padding: 1.5rem 4.4rem;

        .icon {
          font-size: 3rem;
          line-height: 1;
          margin-right: 3rem;
          transition: margin-right .3s;
        }

        &:hover {
          .icon {
            margin-right: 2rem;
          }
        }

        .block_text {
          max-width: 21rem;
        }
      }

      &:nth-child(2n) {
        background-color: $color6;

        a {
          color: #302D29;
        }
      }

      &:last-child {
        height: calc(100% / 4 - .1rem);
      }
    }
  }

  .slider_home_c {
    width: 100%;
    max-width: calc(100% - 36rem);
    margin-left: auto;

    .slider_home {
      margin-bottom: 0;

      li {
        &.ratio {
          --ratio: 1120/560;
          overflow: hidden;
        }

        .no_link,
        a {
          .overlay_slider {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgba(0, 0, 0, .0);
          }

          .islide_content .islide {
            color: var(--color1);
            background-color: rgba(255, 255, 255, .9);
            padding: 3.5rem;
            max-width: 70%;
            max-height: 60%;

            p {
              font-size: 1.8rem;
              color: $color16;
            }

            button {
              margin-top: 2rem;
            }
          }
        }
      }
    }
  }
}

// Slider marques Homepage //
.slider_marques{
  .marques-slider .slick-track {
    display: flex;
    align-items: center;
  }
  .slick-slide {
    margin-left: 3.5rem;
    margin-right: 3.5rem;

    img {
      object-fit: contain;
    }
  }
  .slick-slider.slick-dotted .slick-dots{
    bottom: 1.5rem;
    li button{
      background-color: var(--color1);
      height: 1.4rem;
      width: 1.4rem;
    }
  }
}

// Dernières commandes Homepage //
.last_commands {
  margin-top: 8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5.4rem;
  margin-bottom: 2rem;
  @media (max-width: $breakpoint-lg) {
    gap: 2.4rem;
  }

  .last_commands_c {
    display: flex;
    justify-content: space-between;
    gap: 6rem;
    background-color: $color8;
    padding: 2.4rem;
    border-radius: 1.5rem;
    width: calc(100% - 28rem);
    @media (max-width: $breakpoint-lg) {
      flex-direction: column;
      gap: 2.4rem;
    }

    .commands_c {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      width: calc(50% - 3rem);
      @media (max-width: $breakpoint-lg) {
        width: 100%;
      }

      .button {
        max-width: 34rem;
        @media (max-width: $breakpoint-xs) {
          max-width: 100%;
        }
      }
    }

    .h2 {
      font-size: 2.4rem;
      margin-top: 0;
      margin-bottom: .6rem;
    }

    ul {
      list-style-type: none;
      padding-left: 0;

      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 1.8rem;
        padding: .5rem 1.2rem;
        border-bottom: .1rem solid $color9;

        &:last-child {
          border-bottom: none;
        }

        .flex-textes {
          @media (max-width: $breakpoint-xs) {
            display: flex;
            flex-direction: column;
          }
        }

        .date_last_order {
          color: var(--color1);
          margin-right: 4rem;
        }

        a {
          font-size: 2rem;
          color: var(--color3);

          &:hover {
            color: var(--color1);
          }

          .icon {
            display: inline-flex;
          }
        }
      }
    }
  }

  .favorite_product {
    width: 22.6rem;
    text-align: center;
    background-color: var(--color1);
    border-radius: 1.5rem;
    padding: 2.4rem;
    @media (max-width: $breakpoint-sm) {
      width: 22.6rem;
    }

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

      &:hover .icon {
        animation: heartBeat 3s infinite;
      }

      .h3 {
        color: #fff;
        text-align: center;
        font-weight: 700;
        text-transform: initial;
        line-height: 1.2;
        margin-top: 0;
      }

      .icon {
        display: inline-flex;
        color: #fff;
        font-size: 6rem;
        filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, .7));
      }

      p {
        margin-top: 1.6rem;
        color: white;
      }
    }
  }
}

@keyframes heartBeat {
  25% {
    transform: scale(.5)
  }
  100% {
    transform: scale(1.2)
  }
}

// Article CMS Homepage //
.home_block_cms {
  background-color: $color7;
  padding: 10rem 5rem;
  margin-top: 8rem;

  .row{
    display: flex;
    align-items: center;
  }

  .h3 {
    text-transform: initial;
    font-weight: 600;
    margin-bottom: 2rem;
  }

  .home_block_cms_desc {
    font-size: 1.8rem;
    line-height: 1.5;
  }
}

// A la une //
.grid_container_cat {
  margin-top: 4.4rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  column-gap: 2rem;
  row-gap: 4rem;

  .item {
    background-size: cover;
    background-repeat: no-repeat;

    a {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 29rem;
      background-size: cover;
      background-repeat: no-repeat;

      .title_cat {
        color: #fff;
        font-size: 3.5rem;
        font-weight: 600;
        text-transform: uppercase;
        z-index: 1;
        text-align: center;
      }

      .overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, .4);
      }
    }
  }

  .item-2 {
    grid-column: 3 / span 2;
  }

  .item-4 {
    grid-column: 2 / span 2;
  }
}

// Actualités //
.actu_liste {
  display: flex;
  margin-right: -1.5rem;
  margin-left: -1.5rem;

  .actu_card {
    width: calc(100% / 3 - 3rem);
    text-align: center;
    margin-top: 4.5rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;

    .h3 {
      font-size: 2rem;
      font-weight: 600;
      text-transform: initial;
    }

    .h4 {
      font-size: 1.6rem;
      font-weight: 700;
      color: $color16;
      text-transform: uppercase;
      letter-spacing: .1rem;
      margin-top: 1.8rem;
    }

    a {
      display: block;

      img {
        aspect-ratio: 625/450;
        width: 100%;
        object-fit: contain;
      }
    }

    p {
      line-height: 2;
    }
  }
}

.center_c {
  margin-top: 2rem;
  text-align: center;
}

// Explorez notre catalogue //

.explore_category {
  background-color: $color7;
  margin-top: 8rem;
  padding: 3rem 5rem;

  .nav-tabs {
    justify-content: center;
    margin-top: 3rem;
    border-top: .1rem solid $color10;
    border-bottom: .1rem solid $color10;

    .nav-item {
      width: 20rem;
      text-align: center;

      .nav-link {
        position: relative;
        padding: .7rem 0;
        font-size: 1.6rem;
        font-weight: 700;
        color: var(--color1);
        text-transform: uppercase;
        border-bottom: 5px solid transparent;
        border-radius: 0;

        &:focus,
        &:hover {
          border-color: transparent;
          border-bottom-color: var(--color1);
        }

        &::after {
          content: "";
          position: absolute;
          bottom: -.4rem;
          left: 50%;
          transform: translate(-50%, 100%);
          display: block;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 10px 8.5px 0 8.5px;
          border-color: transparent transparent transparent transparent;
          transition: border-color .4s;
        }
      }

      .nav-item.show .nav-link,
      .nav-link.active {
        border-color: transparent;
        color: #fff;
        background-color: var(--color1);
        opacity: 1;
        border-bottom-color: var(--color2);

        &::after {
          border-color: var(--color2) transparent transparent transparent;
        }
      }
    }
  }

  .slider_home_product {
    margin-top: 6rem;

    .product-card {
      position: relative;
      padding-left: 2rem;
      padding-right: 2rem;

      img {
        height: 25rem;
        margin-left: auto;
        margin-right: auto;
        object-fit: contain;
      }

      .slider_home_product_content {
        text-align: center;
        margin-top: 1rem;

        a {
          height: 6.6rem;
          display: inline-block;
        }

        div,
        a {
          font-size: 1.8rem;
          color: var(--color1);
          font-weight: 700;
          line-height: 1;
        }

        a:hover {
          color: var(--color2);
        }

        .global_price {
          display: none;
        }

        div {
          margin-top: .5rem;
        }
      }
    }
  }
}

.slider_editherm{
  margin-top: 8rem;
  cursor: pointer;
}

.tab-content > .tab-pane {
  display: block;
  height: 0;
  overflow: hidden;
}

.tab-content > .active {
  height: auto;
  overflow: visible;
}

.flag_product {
  color: #fff;
  background-color: var(--color3);
  text-transform: uppercase;
  font-size: 1.1rem;
  font-weight: 700;
  padding: .6rem 1.4rem;
}

.flag_new_product {
  position: absolute;
  top: 1rem;
  left: 4rem;
}

// Nos adresses //
.home_adress {
  display: flex;
  width: 100%;
  margin-top: 8rem;
}

.adress_list,
#home_map {
  min-height: 38rem;
}

.adress_list {
  color: #fff;
  background-color: var(--color1);
  width: 54rem;
  padding: 2rem 4rem 0;
  max-height: 60.4rem;
  overflow: auto;

  .h2 {
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 3.5rem;

    span {
      background-color: var(--color1);
      padding: 0 3.5rem;
    }

    &::after {
      background-color: #fff;
    }
  }

  ul {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;

    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid rgba(255, 255, 255, .3);
      padding-bottom: 18px;
      margin-bottom: 15px;

      &:last-child {
        border-bottom: none;
        margin-bottom: 0;
      }

      .h4 {
        color: #fff;
        font-size: 1.6rem;
        font-weight: 700;
        text-align: left;
      }

      .adress_list_content > div {
        opacity: .8;
      }

      .adress_list_links {
        display: flex;
        justify-content: space-between;
      }

      a {
        display: block;
        height: 100%;
        color: #fff;
        background-color: rgba(255, 255, 255, .2);
        padding: .5rem;

        + a {
          margin-left: 1rem;
        }
      }

      a .icon {
        color: #fff;
      }
    }
  }
}

#home_map {
  width: calc(100% - 54rem);

  .leaflet-popup,
  .leaflet-popup-content,
  .leaflet-popup-content-wrapper {
    height: auto;
  }
}

/* Loader générique */
.loader_generique {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .5);
  z-index: 9999999;

  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media (max-width: $breakpoint-xl) {
  .slider_home_section .block_slider_c .block_slider a {
    padding: 2rem;
  }
}

@media (max-width: $breakpoint-lg) {
  .slider_home_section .block_slider_c {
    max-width: 30rem;
  }
  .slider_home_section .slider_home_c {
    max-width: calc(100% - 30rem);
  }
  .slider_home_section .slider_home_c .slider_home li {
    .no_link,
    a {
      .islide_content .islide {
        padding: 1rem;
        max-width: 90%;
        max-height: 80%;

        .h2 {
          font-size: 2rem;
          margin-top: 0;
        }

        p {
          font-size: 1.6rem;
        }

        button {
          margin-top: 1rem;
        }
      }
    }
  }
  .slider_home_section .block_slider_c .block_slider a {
    padding: 1.5rem;
  }
  .adress_list {
    width: 48rem;
  }
  #home_map {
    width: calc(100% - 48rem);
  }
  .last_commands .last_commands_c {
    padding: 2rem;
    width: calc(100% - 25rem);
  }
  .grid_container_cat {
    row-gap: 2rem;

    .item a {
      height: 24rem;

      .title_cat {
        font-size: 2.8rem;
        text-align: center;
      }
    }
  }
  .home_block_cms {
    padding: 5rem 2rem;
    margin-top: 4rem;

    .home_block_cms_desc {
      font-size: 1.5rem;
    }
  }
  .explore_category {
    margin-top: 4rem;
  }
}

@media (max-width: $breakpoint-md) {
  .slider_home_section .block_slider_c {
    max-width: 26rem;

    .block_slider a {
      padding: .5rem;
      font-size: 1.5rem;

      .icon {
        margin-right: 1rem;
      }

      &:hover .icon {
        margin-right: 1.4rem;
      }
    }
  }
  .slider_home_section .slider_home_c {
    max-width: calc(100% - 26rem);
  }
  .actu_liste {
    flex-wrap: wrap;

    .actu_card {
      width: calc(100% / 2 - 3rem);
    }
  }
  .page_home .actu_liste .actu_card:last-child {
    display: none;
  }
  .home_adress {
    margin-top: 4rem;
    flex-wrap: wrap;
  }
  .adress_list_c {
    width: 100%;

    .adress_list {
      width: 100%;
      padding: .5rem 2rem 0;
      min-height: auto;

      .h2 {
        margin-bottom: 1.5rem;
      }
    }
  }
  #home_map {
    width: 100%;
  }
  .last_commands {
    .last_commands_c {
      width: calc(100% - 18rem);

      .commands_c {

      }
    }
  }
  .grid_container_cat .item a {
    height: 15rem;

    .title_cat {
      font-size: 2.2rem;
    }
  }
  .home_block_cms {
    padding: 2rem;
  }
  .explore_category {
    margin-top: 2rem;
    padding: 1rem 2rem;

    .slider_home_product .product-card img {
      height: 15rem;
    }
  }
}

@media (max-width: $breakpoint-sm) {
  .slider_home_section {
    flex-wrap: wrap;

    .block_slider_c,
    .slider_home_c {
      width: 100%;
      max-width: none;
    }

    .block_slider_c {
      order: 1;
      margin-top: -.2rem;

      .block_slider a {
        .icon {
          margin-right: 2rem;
        }

        .block_text {
          max-width: none;
        }
      }
    }
  }
  .last_commands {
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 4rem;

    .last_commands_c {
      width: 100%;
    }

  }
  .grid_container_cat {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    column-gap: 1.5rem;
    row-gap: 1.5rem;

    .item-2,
    .item-4 {
      grid-column: auto;
    }
  }
  .explore_category .nav-tabs .nav-item {
    width: auto;

    .nav-link {
      padding-left: 1.4rem;
      padding-right: 1.4rem;
    }
  }
}

@media (max-width: $breakpoint-xs) {
  .slider_home_section .slider_home_c .slider_home li {
    .no_link,
    a {
      .islide_content .islide p {
        display: none;
      }
    }
  }
  .actu_liste .actu_card {
    margin-top: 2.5rem;
    width: 100%;
  }
  .grid_container_cat {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .home_block_cms {
    padding: 1rem;
  }
  .explore_category .nav-tabs .nav-item {
    width: 100%;

    .nav-link {
      width: 100%;

      &:after {
        content: none;
      }
    }
  }
}