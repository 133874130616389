/* Image produit + vignettes */

.slider-single{
  .image_produit_container{
    position: relative;
    padding-bottom: 100%;
    .image_produit_content{
      @include absolute_container;
      @include box_shadow;
      @include flex_center;
      margin: 1rem;
      cursor: pointer;
      img{
        max-height: 100%;
      }
      .img-campagne{
        position: absolute;
        top: .5rem;
        right: .5rem;
        width: 7rem;
      }
    }
  }
}

.slider-nav{
  .slick-track{
    margin: 0 1rem 1rem;
    .slick-slide{
      margin-right: 1.5rem;
      img{
        object-fit: contain;
        @include box_shadow_light;
        cursor: pointer;
        //height: 7.5rem;
        border: 2px solid transparent;
      }
    }
  }
  .slick-slide.is-active img{
    border-color: var(--color1);
  }
}

/* Informations produit colonne droite */
.col_infos_produit{
  form{
    //max-width: 73rem;
    margin-left: auto;
    margin-right: auto;
    h1{
      font-size: 3.2rem;
      font-weight: 600;
      text-align: left;
      text-transform: initial;
      margin-top: 2rem;
      margin-bottom: 1.5rem;
    }
    .ref_marque_c{
      display: flex;
      justify-content: space-between;
      img{
        max-width: 150px;
        max-height: 150px;
      }
    }
    .sub_title_product{
      @include flex_between;
      margin-top: 2rem;
      margin-bottom: 3.4rem;
      a{
        text-decoration: underline;
        &:hover{
          color: $color16;
        }
      }
    }
  }
  .campagne_block{
    .h3{
      font-size: 1.8rem;
      .icon{
        margin-right: .6rem;
      }
    }
    .nb-points{
      font-weight: 600;
    }
  }
}

.alert_product{
  font-weight: 600;
  span{
    @include icon_rounded(2.7rem);
    margin-right: 5px;
  }
}

.grey_block{
  background-color: $color7;
  padding: 2rem 2.5rem;
  margin-bottom: 2rem;
  .old_price_c{
    margin-bottom: 2rem;
    .old-price{
      font-size: 1.8rem;
      font-weight: 600;
      &::after{
        border-top-color: $color16;
      }
    }
  }
  .flag_product{
    font-size: 1.4rem;
    font-weight: 600;
    margin-left: .8rem;
  }
  .block_prix{
    font-weight: 600;
    color: var(--color1);
    margin-bottom: 1rem;
    .highlight{
      font-size: 2.8rem;
      font-weight: 700;
    }
    .global_price{
      color: $color16;
    }
  }
  .quantity_block_product{
    @include flex_between;
    gap: 1.6rem;
    flex-wrap: wrap;
    margin: 3rem 0;

    .input_box{
      width: 21rem;
    }
    .select_box{
      width: 38rem;
    }
    label{
      font-weight: 600;
      color: var(--color1);
    }
    input,
    select{
      width: 12rem;
      height: 5rem;
      border: .1rem solid $color9;
      padding-left: .8rem;
    }
    .select_c{
      position: relative;
      display: block;
      cursor: pointer;
      select{
        appearance: none;
        outline: 0;
        cursor: pointer;
        width: 100%;
      }
      &::after{
        content: "\EA15";
        display: block;
        position: absolute;
        top: 1.98rem;
        right: 1rem;
        font-family: kfont;
        font-size: 1rem;
        pointer-events: none;
      }
    }
    &.block_simple{
      justify-content: flex-start;
      .input_box:first-child{
        margin-right: 4rem;
      }
    }
  }
}

.stock_block{
  @include flex_between;
  .stock{
    display: block;
    font-weight: 600;
    .highlight{
      font-size: 1.6rem;
      margin-bottom: .5rem;
    }
    .icon-stock {
      width: 1.8rem;
      height: 1.8rem;
      margin-bottom: -.4rem;
    }
  }
  .button-filed{
    @include button-filed($color9);
    color: #606060;
    padding: .6rem 3rem;
  }
}

.add_cart_c{
  .form-actions{
    display: flex;
    align-items: center;
    .button{
      height: 5.4rem;
      padding: 1.2rem 6.5rem;
      margin-right: 1rem;
      cursor: pointer;
      .icon{
        font-size: 2.5rem;
        margin-right: 1.5rem;
      }
      &:hover{
        background-color: #fff;
        color: var(--color1);
      }
      &.button-disable{
        cursor: not-allowed;
        &:hover{
          border-color: $color12;
          background-color: $color12;
          color: #fff;
        }
      }
    }
    .button-filed{
      display: inline-block;
      @include button-filed(var(--color1));
      height: 5.4rem;
      line-height: 5.8rem;
      width: 5.4rem;
      padding: 0;
      text-align: center;
      .icon{
        margin-right: 0;
      }
    }
  }
}

.grey_block_slider{
  background-color: $color7;
  padding: 4.5rem 3rem 7rem;
  margin-top: 5rem;
  .h3{
    font-size: 3.2rem;
    text-align: center;
    margin-bottom: 4rem;
    span{
      background-color: $color7;
    }
  }
  .slider_product_c{
    & + .slider_product_c{
      margin-top: 4rem;
    }
    .products_list_card{
      margin-left: 10rem;
      margin-right: 10rem;
      .slick-list{
        width: 100%!important;
      }
      .slick-slide {
        margin-right: 2rem;
      }
      .slick-next{
        right: -11.5rem;
      }
      .slick-prev {
        left: -11.5rem;
      }
      .slick-next::before,
      .slick-prev::before {
        color: #A39E9B;
      }
    }
  }
}

.desc_produit_c{
  margin-bottom: 13rem;
  .h2{
    font-size: 2.8rem;
    margin-top: 6rem;
  }
  .h4{
    font-size: 1.6rem;
    color: #000;
  }
  p{
    margin-bottom: 1.5rem;
  }
  .alert_product span{
    margin-right: 1rem;
  }
  .table_caract_line{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 6rem;
    &:nth-child(2n + 1){
      background-color: #f5f5f5;
    }
    .libelle_caract{
      width: 50%;
      padding-left: 3rem;
    }
    .value_caract{
      width: 50%;
    }
  }
  .doc_produit_c{
    .doc_produit_liste{
      margin-top: 3rem;
      margin-bottom: 3rem;
      a{
        font-size: 1.6rem;
        font-weight: 600;
        .icon{
          font-size: 4rem;
          color: #707070;
          margin-right: 1.3rem;
        }

        & + a{
          margin-left: 8rem;
        }
      }
    }
    .button{
      padding: 12px 50px;
      .icon{
        margin-right: 1.5rem;
        font-size: 2.5rem;
      }
    }
  }
}

.connect-box{
  max-width: 40rem;
  background-color: #f9f9f9;
  border: .2rem solid var(--color1);
  padding: 2rem;
  margin: 3rem auto;
}

.select-config {
  @media (max-width: $breakpoint-xs) {
    padding: 2rem 1rem !important;
  }

  .flex-qt {
    display: flex;
    align-items: flex-end;
    gap: 1rem;
    @media (max-width: $breakpoint-xs) {
      flex-direction: column;
      align-items: flex-start;
    }

    .input_box label {
      font-weight: 600;
      color: var(--color1);
    }

    .block_quantity {
      width: unset;
      margin: 0 !important;

      .qt_block {
        margin-top: 0;
        margin-bottom: 0;
        height: 5.4rem;

        & > * {
          height: 100%;
          margin: 0 !important;
        }

        .inpQty {
          width: 8rem;
        }

        .qt_manage {
          padding-top: .6rem;
        }
      }
    }
  }
  .stock_block_select {
    justify-content: left;
    gap: 1rem;
    margin-bottom: 0 !important;

    & > .block {
      @media (max-width: $breakpoint-xs) {
        margin-bottom: 0 !important;
      }
    }
  }

  .flex-desc {
    display: flex;
    flex-wrap: wrap;
    gap: 2.4rem;

    & > * {
      width: calc(50% - 1.2rem);
      @media (max-width: $breakpoint-sm) {
        width: 100%;
      }
    }
  }

  .quantity_block_product {
    @media (max-width: $breakpoint-xs) {
      margin: 2rem auto;
    }
    .select_box {
      width: calc(50% - .8rem);
      margin-top: 0 !important;
      @media (max-width: $breakpoint-xs) {
        width: 100%;
      }
    }
  }

  .add_cart_c {
    button {
      @media (max-width: $breakpoint-md) {
        padding: 1.2rem 3rem !important;
      }
    }
  }
}

.flex-desc {
  display: flex;
  flex-wrap: wrap;
  gap: 2.4rem;

  & > * {
    width: calc(50% - 1.2rem);
    @media (max-width: $breakpoint-sm) {
      width: 100%;
    }
  }
}

@media (max-width: $breakpoint-xl) {
  .col_infos_produit form{
    max-width: none;
  }
  .grey_block .quantity_block_product .select_box{
    width: 22rem;
  }
  .grey_block_slider .slider_product_c .products_list_card{
    margin-left: 2rem;
    margin-right: 2rem;
    .slick-prev{
      left: -3.5rem;
    }
    .slick-next{
      right: -3.5rem;
    }
  }
}

@media (max-width: $breakpoint-md) {
  .bloc_img_produit{
    max-width: 40rem;
  }
  .grey_block .quantity_block_product .select_box{
    width: 38rem;
  }
}

@media (max-width: $breakpoint-xs) {
  .col_infos_produit{
    form h1{
      font-size: 2.5rem;
    }
    .sub_title_product{
      flex-wrap: wrap;
      a{
        width: 100%;
        margin-bottom: 1rem;
      }
    }
    .stock_block{
      flex-wrap: wrap;
      margin-bottom: 3rem;
      .block{
        margin-bottom: 1.5rem;
      }
      .button-filed{
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }
  }
  .add_cart_c .form-actions .button{
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .grey_block{
    padding: 1rem;
    .quantity_block_product{
      flex-wrap: wrap;
      justify-content: flex-start;
      .input_box + .input_box{
        margin-left: 2rem;
      }
      .select_box{
        width: 100%;
        margin-top: 1.5rem;
      }
    }
  }
  .grey_block_slider{
    padding: 1rem;
    margin-top: 0;
    .h3{
      font-size: 2.2rem;
    }
    .slider_product_c .products_list_card{
      margin-left: 1rem;
      margin-right: 1rem;
      .slick-slide{
        margin-left: .5rem;
        margin-right: .5rem;
      }
    }
  }
  .grey_block .quantity_block_product.block_simple{
    .input_box:first-child{
      margin-right: 2rem;
    }
    .input_box + .input_box{
      margin-left: 0;
    }
  }
}

.desc_produit_c{
  table{
    width: 100%;
    margin-top: 2rem;
    margin-left: .1rem;
    margin-bottom: 1.5rem;
    border-bottom: .1rem solid #ccc;
    tr{
      height: 5rem;
      border-bottom: .1rem solid #ccc;
    }
    th,
    td{
      text-align: center;
      border-right: .1rem solid #ccc;
    }
    th:last-child,
    td:last-child{
      border-right: none;
    }
    td:first-child{
      font-weight: 700;
    }
    thead{
      tr{
        color: #fff;
        th{
          position: relative;
          text-transform: uppercase;
          background-color: var(--color1);
          &.th-order{
            cursor: pointer;
            transition: opacity .3s;
            &:hover{
              opacity: .8;
            }
            .entete-tableau{
              display: flex;
              > span{
                width: calc(100% - 1.6rem);
                line-height: 1.1;
                margin-right: 1.6rem;
              }
              .icon-tri{
                display: flex;
                .icon{
                  position: absolute;
                  right: .4rem;
                  &.icon-carret-up{
                    top: 1.2rem;
                    opacity: .5;
                  }
                  &.icon-carret-down{
                    top: 2.2rem;
                    opacity: .5;
                  }
                }
              }
            }
            &.descent .entete-tableau .icon-tri{
              .icon.icon-carret-up{
                opacity: 1;
              }
              .icon.icon-carret-down{
                opacity: .5;
              }
            }
            &.asc .entete-tableau .icon-tri{
              .icon.icon-carret-up{
                opacity: .5;
              }
              .icon.icon-carret-down{
                opacity: 1;
              }
            }
          }
          &.w-120{
            width: 12rem;
          }
          &.w26{
            width: 26rem;
          }
          &.no-order{
            cursor: default;
            &:hover{
              opacity: 1;
            }
            .icon-arrow{
              display: none;
            }
          }
        }
      }
    }
    tbody{
      tr{
        border-left: .1rem solid #ccc;
        border-right: .1rem solid #ccc;
      }
      tr.ligne_declinaison:nth-child(2n){
        background-color: #dedddd;
      }
      .stock{
        justify-content: center;
      }
      #quantityVte{
        width: 10rem;
        text-align: center;
      }
      form{
        a:hover{
          color: #777;
        }
        .button{
          padding: .5rem 3rem;
        }
      }
      .icon{
        font-size: 3rem;
        line-height: .9;
        &.icon-heart,
        &.icon-full-heart{
          margin-right: 1.5rem;
          margin-left: 1.5rem;
        }
      }
      .stock .icon-stock{
        margin-top: .4rem;
      }
    }
  }
}
.stock.stock_legende{
  > div{
    display: flex;
    align-items: center;
  }
  .icon-stock{
    margin-left: 0;
    margin-right: .6rem;
  }
  .sep-stock{
    margin-left: .5rem;
    margin-right: .8rem;
  }
}

@media (max-width: $breakpoint-md) {
  .detail_prod_table{
    overflow-x: auto;
  }
  .desc_produit_c table{
    width: 100rem;
  }
  .stock.stock_legende{
    flex-direction: column;
    align-items: flex-start;
    margin-top: .8rem;
    .sep-stock{
      display: none;
    }
  }
}

@media (max-width: $breakpoint-xs) {
  .header_content .line2{
    .shop-action > .link_account_header{
      display: none;
    }
    .search-bar{
      margin-right: 1rem;
      width: calc(100% - 12rem);
    }
  }
}
.link_account_header .name_header {
  display: none;
}
.infos_product{
  .more_declinaisons{
    padding: 10px;
    color: $color16;
    font-size: 13px;
    font-weight: 400;
  }
}
