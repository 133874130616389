.offre {
  margin-bottom: 2.5rem;
  margin-top: 2.5rem;
  border: .1rem solid var(--color2);
  cursor: pointer;

  &.min {
    max-height: 17rem;
    padding-bottom: 1rem;
    overflow: hidden;
    @media (max-width: 767px){
      max-height: 30rem;
    }

    .offre-description {
      .icon {
        -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
      }
    }
  }
  .offre-description {
    position: relative;
    .icon {
      position: absolute;
      right: 2rem;
      top: 1rem;
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
      transform-origin: center center;
      transition: all .300ms ease-in-out;
    }
  }
  .offre-header {
    background-color: #eee;
    .form_recrutement {
      form {
        .col-12 {
          display: flex;
          justify-content: space-between;
          margin-top:0.5rem;
          margin-bottom:0.5rem;

          .label-title {
            font-size: 1.6rem;
            margin-right: 0.5rem;
          }
        }
      }
    }
  }
}
